<template>
  <div>
    <div class="header"></div>
    <div class="banner"></div>
    <div class="container"></div>
    <div class="main">
      <el-tabs v-model="activeName" @tab-click="handleClick" class="tab_main">
        <el-tab-pane label="存款和储蓄账户" name="first">提供各种类型的存款和储蓄账户，例如个人储蓄账户、支票账户和定期存款账户。这些账户可以用于安全存放资金、进行日常交易和积累利息。</el-tab-pane>
        <el-tab-pane label="贷款和信贷" name="second">提供各种类型的贷款和信贷服务，包括个人贷款、商业贷款、房屋贷款和信用卡。这些服务可以帮助个人和企业获得所需的资金，并根据合同约定进行还款。</el-tab-pane>
        <el-tab-pane label="投资和理财" name="third">提供投资和理财服务，帮助客户管理和增值他们的资金。这些服务可能包括股票交易、债券投资、基金投资、退休计划、保险和财务规划等。</el-tab-pane>
        <el-tab-pane label="外汇交易和跨境支付" name="fourth">提供外汇交易和跨境支付服务，帮助个人和企业进行跨国货币兑换和支付。这些服务可以促进国际贸易和资金流动。</el-tab-pane>
      </el-tabs>
    </div>
    <div class="main1">
      <h5>服务内容</h5>
      <div>
        <!-- <img src="../assets/image/liuc.png" alt="" /> -->
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        activeName: 'second'
      };
    },
    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      }
    }
  };
</script>
<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  font-size: 18px;
}

a {
  text-decoration: none;
}

li {
  text-decoration: none;
  list-style-type: none;
}

h1 {
  font-size: 29px;
}

.nav {
  max-width: 2100px;
  min-width: 1600px;
  width: 100%;
  margin: 0 auto;
  height: 60px;
  text-align: center;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  font-family: Roboto;
  border: 1px solid rgba(255, 255, 255, 1);
}

.nav {
  display: flex;
}

.logo {
  flex: 1;
  height: 100%;
}

.logo img {
  width: 49%;
  height: 60px;
}

.nav li {
  display: inline-block;
}

.nav li a {
  display: inline-block;
  padding: 0 24px;
  height: 60px;
  line-height: 60px;
  color: black;
  text-decoration: none;
  font-size: 20px !important;
}

.nav li a:hover {
  background-color: #4774b6;
  color: #ffffff;
}

.ul {
  flex: 3;
}

.login {
  flex: 1;
  margin-right: 80px;
}

.login li {
  float: right;
}

.login li a {
  /* float: right; */
  padding: 0 15px;
}

.boxnav {
  position: absolute;
  right: 80px;
  top: 0;
  width: 235px;
  height: 370px;
  /* background-color: rgba(251, 248, 248, 0.5); */
  text-align: center;
  border: 0px solid rgba(187, 187, 187, 1);
  z-index: 9999;
  padding-top: 107px;
  box-sizing: border-box;
}

.boxnav input {
  width: 154px;
  height: 50px;
  line-height: 50px;
  font-size: 20px;
  color: #ffffff;
  border: 0;
  border-radius: 4px;
}

.blue-btn {
  background-color: #0f40f5;
  margin-bottom: 60px;
}

.yellow-btn {
  background-color: #e99d42;
}

#banner {
  max-width: 2100px;
  min-width: 1600px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  width: 100%;
  margin: 0 auto;
  height: 770px;
}

.footer {
  background-color: #f0f0f0;
}

.footer > div {
  height: 399px;
  width: 1200px;
  margin: 0 auto;
  display: flex;
}

.fo1 {
  flex: 5;
  padding-top: 80px;
}

.fo1 p {
  padding-top: 27px;
  margin-bottom: 44px;
  color: #b4b4b4;
  font-size: 20px;
}

.fo1 h3 {
  line-height: 32px;
  font-size: 20px;
  font-weight: 400;
}

.fo2 {
  flex: 2;
  padding-top: 83px;
}

.fo3 {
  flex: 2;
  padding-top: 83px;
}

.fo4 {
  flex: 2;
  padding-top: 66px;
}

.fo2 h4 {
  margin-bottom: 38px;
  color: #b0b0b0;
  font-size: 20px;
}

.fo3 h4 {
  margin-bottom: 38px;
  color: #b0b0b0;
  font-size: 20px;
}

.footer li {
  height: 35px;
  color: #434343;
}

.fo4 {
  text-align: center;
}

.fo4 img {
  margin-bottom: 20px;
}

.fo4 p {
  color: #434343;
  font-size: 20px;
  text-align: center;
}
.banner {
  height: 350px;
  background: url(../assets/image/banner1.jpg) no-repeat 0 0;
  background-size: 100% 100%;
  margin-bottom: 36px;
}

.container {
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 763px;
}

.container p {
  display: inline-block;
  color: #7799c9;
  font-weight: 800;
  font-size: 20px;
}

h5 {
  font-size: 16px;
  display: inline-block;
  margin-left: 14px;
  font-weight: 400;
}

.title {
  width: 164px;
  padding-bottom: 15px;
  border-bottom: 2px solid #ececec;
  margin-bottom: 108px;
}

.container > p {
  line-height: 54px;
  font-size: 20px;
  color: #101010;
  font-weight: 400;
}
.main {
  width: 1276px;
  height: 250px;
  margin: 0 auto;
  background-color: rgba(239, 239, 239, 1);
  color: black;
  font-weight: 400;
 padding: 40px 16px 0 16px;
  box-sizing: content-box;
  margin-bottom: 315px;
}
.tab_main {
  height: 206px;
  padding: 20px;
  box-sizing: border-box;
  background: #ffffff; 
  margin-left: 20px; 
  margin-right: 20px;
}

.main1 {
  width: 1276px;
  margin: 0 auto;
  margin-bottom: 451px;
}

.main1 h5 {
  border-bottom: 2px solid #108ee9;
  padding: 5px 15px 5px;
  margin-left: 0;
  margin-bottom: 45px;
  color: #0095e2;
}

.main1 p {
  height: 70px;
}

.main h1 {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 36px;
  color: #101010;
}

.main p {
  font-size: 18px;
  color: black;
  font-weight: 400;
  line-height: 27px;
}

.container {
  margin-bottom: 0px;
}
</style>
