import { render, staticRenderFns } from "./serviceAll.vue?vue&type=template&id=77be384f&scoped=true&"
var script = {}
import style0 from "./serviceAll.vue?vue&type=style&index=0&id=77be384f&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77be384f",
  null
  
)

export default component.exports