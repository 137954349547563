// router.js

import Vue from 'vue';
import VueRouter from 'vue-router';

// 导入需要路由的组件
import indexAll from '../components/indexAll.vue';
import platformIntroduction from '../components/platformIntroduction.vue';
import serviceAll from '../components/serviceAll.vue';
import successEg from '../components/successEg.vue';
import contactUs from '../components/contactUs.vue';
import customsClearance from '../components/customsClearance.vue';
import logisticsW from '../components/logisticsW.vue';
import refundofDuty from '../components/refundofDuty.vue';
import foreignExchange from '../components/foreignExchange.vue';
import financeDetail from '../components/financeDetail.vue';
import sinosureDetail from '../components/sinosureDetail.vue';
Vue.use(VueRouter);

const routes = [
  { path: '/', component: indexAll },
  { path: '/platform', component: platformIntroduction },
  { path: '/serviceAll', component: serviceAll },
  { path: '/successEg', component: successEg },
  { path: '/contactUs', component: contactUs },
  { path: '/customsClearance', component: customsClearance},
  { path: '/logisticsW', component: logisticsW},
  { path: '/refundofDuty', component: refundofDuty},
  { path: '/foreignExchange', component: foreignExchange},
  { path: '/financeDetail', component: financeDetail},
  { path: '/sinosureDetail', component: sinosureDetail},
];


const router = new VueRouter({
  routes,
  mode: 'history', // 使用"history"模式，去掉URL中的哈希符号
  
scrollBehavior(to, from, savedPosition) {
  // 如果有 savedPosition，则恢复到保存的位置
  if (savedPosition) {
    return savedPosition;
  } else {
    // 否则滚动到页面的顶部
    return { x: 0, y: 0 };
  }
}
});

export default router;
