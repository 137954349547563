<template>
  <div id="app">
    <div class="nav">
      <div class="logo">
        <img src="./assets/image/logo1.png" alt="" style="margin-top: 2%;"/>
        <img src="./assets/image/logo2.png" alt="" />
      </div>
      <ul>
        <li
          v-for="(item, index) in menuItems"
          :key="index"
          @click="selectMenuItem(index)"
          :class="{ active: selectedIndex === index }"
          style="font-size: 20px;"
        >
          <router-link :to="item.to"  :style="{ color: item.textColor }">{{ item.label }}</router-link>
        </li>
      </ul>
      <div class="login">
        <ul>
          <li
            v-for="(item, index) in loginItems"
            :key="index"
          >
            <a :href="item.src">{{ item.label }}</a>
          </li>
        </ul>
      </div>
    </div>
    <router-view></router-view>
    <div class="footer">
      <div>
        <div class="fo1">
          <h3>佛山市南海区机械装备行业协会</h3>
          <p style="font-size: 18px;">地址:佛山市南海区桂城瀚天科技城A区8号副楼企业创享家内</p>
          <h3>全国客服热线</h3>
          <h3>13927710212、18566078227、15017724080</h3>
        </div>
        <div class="fo2">
          <img class="fo2_img" src="../src/assets/image/phone.jpg" alt="">
        </div>
        <div class="fo4">
          <img class="fo4_img" src="../src/assets/image/scan.png" alt="" />
          <p>关注公众号</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    data() {
    return {
      menuItems: [
        { to: '/', label: '首页',textColor:'black' },
        { to: '/platform', label: '平台介绍',textColor:'black' },
        { to: '/serviceAll', label: '综合服务',textColor:'black' },
        { to: '/successEg', label: '相关活动',textColor:'black' },
        { to: '/contactUs', label: '资质荣誉',textColor:'black' },
      ],
      loginItems: [
        { src:'http://www.fswzf.cn:9001',label:'操作平台' },
        { src:'http://www.fswzf.cn:9001/regist',label:'注册' },
        { src:'http://www.fswzf.cn:9001',label:'登录' },
      ],
      selectedIndex: null, // 存储当前选定的菜单项索引
      selectedLoginIndex: null, // 存储当前选定的登录菜单项索引
    };
  },
  mounted() {
    // 默认选择首页
    this.selectMenuItem(0); // 0 是首页的索引
  },
  methods: {
    selectMenuItem(index) {
      // 点击菜单项时更新选定的索引
      this.selectedIndex = index;
      this.updateTextColors(this.menuItems, index);
    },
    // selectLoginItem(index) {
    //   // 点击登录菜单项时更新选定的索引
    //   this.selectedLoginIndex = index;
    // },
    updateTextColors(items, selectedIndex) {
      // 更新文字颜色
      items.forEach((item, index) => {
        item.textColor = index === selectedIndex ? 'white' : 'black'; // 修改选中项的颜色
      });
    },
  },
  name: "App",
  components: {},
};
</script>

<style lang="scss" scoped>
@import url(./assets/css/index.css);
@import url(./assets/css/common.css);
ul li {
  cursor: pointer;
  list-style: none;
  transition: background-color 0.2s;
}

/* 被选中时的样式 */
ul li.active {
  background-color: #4774b6;
}

</style>
