<template>
  <div>
    <div class="header"></div>
    <div class="banner"></div>
    <div class="container"></div>
    <div class="main">
      <el-tabs v-model="activeName" @tab-click="handleClick" class="tab_main">
        <el-tab-pane label="海运" name="first">1、整柜（FCL）、拼箱（LCL）服务<br>2、多式联运服务<br>3、港口代理、船务代理服务<br>4、海上保险服务、货物跟踪和信息管理</el-tab-pane>
        <el-tab-pane label="空运" name="second">1、机场到机场的空运服务<br>2、优质快捷的门到门服务<br>3、海空联运服务<br>4、代办清关手续，到付运费等增值服务</el-tab-pane>
        <el-tab-pane label="国际铁路" name="third">1、国际铁路集装箱运输<br>2、跨国铁路联运服务<br>3、国际铁路运输代理服务<br>4、跨境铁路电商物流服务</el-tab-pane>
        <el-tab-pane label="拖车" name="fourth">1、国际拖车运输服务<br>2、国内拖车服务<br>3、跨境车辆运输代理服务<br>4、车辆搬迁服务</el-tab-pane>
        <el-tab-pane label="中港运输" name="fifth">1、中港货运服务<br>2、港口货运服务<br>3、跨境电商物流服务<br>4、中港物流代理服务</el-tab-pane
        >
      </el-tabs>
    </div>
    <div class="main1">
      <h5>服务内容</h5>
      <div>
        <img src="../assets/image/liuc.png" alt="" />
      </div>
    </div>
  </div>
  <!-- <script src="./js/logistics.js"></script> -->
</template>
<script>
  export default {
    data() {
      return {
        activeName: 'second'
      };
    },
    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      }
    }
  };
</script>
<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  font-size: 18px;
}

a {
  text-decoration: none;
}

li {
  text-decoration: none;
  list-style-type: none;
}

h1 {
  font-size: 29px;
}

.nav {
  max-width: 2100px;
  min-width: 1600px;
  width: 100%;
  margin: 0 auto;
  height: 60px;
  text-align: center;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  font-family: Roboto;
  border: 1px solid rgba(255, 255, 255, 1);
}

.nav {
  display: flex;
}

.logo {
  flex: 1;
  height: 100%;
}

.logo img {
  width: 49%;
  height: 60px;
}

.nav li {
  display: inline-block;
}

.nav li a {
  display: inline-block;
  padding: 0 24px;
  height: 60px;
  line-height: 60px;
  color: black;
  text-decoration: none;
  font-size: 20px !important;
}

.nav li a:hover {
  background-color: #4774b6;
  color: #ffffff;
}

.ul {
  flex: 3;
}

.login {
  flex: 1;
  margin-right: 80px;
}

.login li {
  float: right;
}

.login li a {
  /* float: right; */
  padding: 0 15px;
}

.boxnav {
  position: absolute;
  right: 80px;
  top: 0;
  width: 235px;
  height: 370px;
  /* background-color: rgba(251, 248, 248, 0.5); */
  text-align: center;
  border: 0px solid rgba(187, 187, 187, 1);
  z-index: 9999;
  padding-top: 107px;
  box-sizing: border-box;
}

.boxnav input {
  width: 154px;
  height: 50px;
  line-height: 50px;
  font-size: 20px;
  color: #ffffff;
  border: 0;
  border-radius: 4px;
}

.blue-btn {
  background-color: #0f40f5;
  margin-bottom: 60px;
}

.yellow-btn {
  background-color: #e99d42;
}

#banner {
  max-width: 2100px;
  min-width: 1600px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  width: 100%;
  margin: 0 auto;
  height: 770px;
}

.footer {
  background-color: #f0f0f0;
}

.footer > div {
  height: 399px;
  width: 1200px;
  margin: 0 auto;
  display: flex;
}

.fo1 {
  flex: 5;
  padding-top: 80px;
}

.fo1 p {
  padding-top: 27px;
  margin-bottom: 44px;
  color: #b4b4b4;
  font-size: 20px;
}

.fo1 h3 {
  line-height: 32px;
  font-size: 20px;
  font-weight: 400;
}

.fo2 {
  flex: 2;
  padding-top: 83px;
}

.fo3 {
  flex: 2;
  padding-top: 83px;
}

.fo4 {
  flex: 2;
  padding-top: 66px;
}

.fo2 h4 {
  margin-bottom: 38px;
  color: #b0b0b0;
  font-size: 20px;
}

.fo3 h4 {
  margin-bottom: 38px;
  color: #b0b0b0;
  font-size: 20px;
}

.footer li {
  height: 35px;
  color: #434343;
}

.fo4 {
  text-align: center;
}

.fo4 img {
  margin-bottom: 20px;
}

.fo4 p {
  color: #434343;
  font-size: 20px;
  text-align: center;
}
.banner {
  height: 350px;
  background: url(../assets/image/banner1.jpg) no-repeat 0 0;
  background-size: 100% 100%;
  margin-bottom: 36px;
}

.container {
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 763px;
}

.container p {
  display: inline-block;
  color: #7799c9;
  font-weight: 800;
  font-size: 20px;
}

h5 {
  font-size: 16px;
  display: inline-block;
  margin-left: 14px;
  font-weight: 400;
}

.title {
  width: 164px;
  padding-bottom: 15px;
  border-bottom: 2px solid #ececec;
  margin-bottom: 108px;
}

.container > p {
  line-height: 54px;
  font-size: 20px;
  color: #101010;
  font-weight: 400;
}
.main {
  width: 1276px;
  height: 250px;
  margin: 0 auto;
  background-color: rgba(239, 239, 239, 1);
  color: black;
  font-weight: 400;
 padding: 40px 16px 0 16px;
  box-sizing: content-box;
  margin-bottom: 315px;
}
.tab_main {
  height: 206px;
  padding: 20px;
  box-sizing: border-box;
  background: #ffffff; 
  margin-left: 20px; 
  margin-right: 20px;
}

.main1 {
  width: 1276px;
  margin: 0 auto;
  margin-bottom: 451px;
}

.main1 h5 {
  border-bottom: 2px solid #108ee9;
  padding: 5px 15px 5px;
  margin-left: 0;
  margin-bottom: 45px;
  color: #0095e2;
}

.main1 p {
  height: 70px;
}

.main h1 {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 36px;
  color: #101010;
}

.main p {
  font-size: 20px;
  color: black;
  font-weight: 400;
  line-height: 27px;
}

.container {
  margin-bottom: 0px;
}
</style>
