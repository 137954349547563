<template>
  <div>
    <div class="header">
      <!-- 轮播图 -->
      <!-- <div id="banner" class="carousel banner"> -->
      <!-- <div class="imgs"> -->
      <el-carousel :interval="5000" arrow="always" height="850px">
        <el-carousel-item v-for="item in banner" :key="item.id">
          <div class="bannerTitle">
            <h1 style="font-size: 45px">一站式通关 · 收付汇 · 物流 · 退税</h1>
            <h2>一对一服务</h2>
          </div>
          <img
            style="width: 100%; height: 850px; object-fit: cover"
            :src="item.src"
            mode="scaleToFill"
          />
        </el-carousel-item>
      </el-carousel>
      <!-- </div> -->

      <!-- <ul id="imgs">
                <li class="active">
                    <img src="../assets/image/1.jpg" alt="">
                </li>
                <li class="unactive">
                    <img src="../assets/image/2.jpg" alt="">
                </li>
                <li class="unactive">
                    <img src="../assets/image/3.jpg" alt="">
                </li>
                <li class="unactive">
                    <img src="../assets/image/4.jpg" alt="">
                </li>
            </ul> -->
      <!-- 轮播图下面的小圆点 -->
      <!-- <ul id="btns">
                <li class="btn_at"></li>
                <li class="btn_unat"></li>
                <li class="btn_unat"></li>
                <li class="btn_unat"></li>
            </ul> -->

      <!-- </div> -->
    </div>
    <div class="container">
      <div class="floor1">
        <div class="title">
          <div class="le">
            <h3>服务项目</h3>
            <p>services</p>
          </div>
          <div class="ri">
            <ul>
              <li>
                <router-link to="/customsClearance">通关</router-link>
              </li>
              <li>
                <router-link to="/refundofDuty">退税</router-link>
              </li>
              <li>
                <router-link to="/foreignExchange">外汇</router-link>
              </li>
              <li>
                <router-link to="/logisticsW">物流</router-link>
              </li>
              <li>
                <router-link to="/financeDetail">金融</router-link>
              </li>
              <li>
                <router-link to="/sinosureDetail">信保</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="box1">
          <div style="margin-left: 0">
            <router-link to="/customsClearance">
              <img class="imgS" src="../assets/image/9.jpg" alt="" />
              <div class="box_item">
                <p style="font-size: 21px; font-weight: 600">通关</p>
                <img src="../assets/image/右箭头.png" class="arrowR" alt="" />
              </div>
            </router-link>
          </div>
          <div>
            <router-link to="/refundofDuty">
              <img class="imgS" src="../assets/image/10.jpg" alt="" />
              <div class="box_item">
                <p style="font-size: 21px; font-weight: 600">退税</p>
                <img src="../assets/image/右箭头.png" class="arrowR" alt="" />
              </div>
            </router-link>
          </div>
          <div style="margin-right: 0">
            <router-link to="/foreignExchange">
              <img class="imgS" src="../assets/image/11.jpg" alt="" />
              <div class="box_item">
                <p style="font-size: 21px; font-weight: 600">外汇</p>
                <img src="../assets/image/右箭头.png" class="arrowR" alt="" />
              </div>
            </router-link>
          </div>
        </div>
        <div class="box1">
          <div style="margin-left: 0">
            <router-link to="/logisticsW">
              <img class="imgS" src="../assets/image/12.jpg" alt="" />
              <div class="box_item">
                <p style="font-size: 21px; font-weight: 600">物流</p>
                <img src="../assets/image/右箭头.png" class="arrowR" alt="" />
              </div>
            </router-link>
          </div>

          <div style="margin-left: 0">
            <router-link to="/financeDetail">
              <img class="imgS" src="../assets/image/13.jpg" alt="" />
              <div class="box_item">
                <p style="font-size: 21px; font-weight: 600">金融</p>
                <img src="../assets/image/右箭头.png" class="arrowR" alt="" />
              </div>
            </router-link>
          </div>
          <div style="margin-right: 0">
            <router-link to="/sinosureDetail">
              <img class="imgS" src="../assets/image/14.jpg" alt="" />
              <div class="box_item">
                <p style="font-size: 21px; font-weight: 600">信保</p>
                <img src="../assets/image/右箭头.png" class="arrowR" alt="" />
              </div>
            </router-link>
          </div>
        </div>
        <div class="liucheng">
          <img src="../assets/image/7.jpg" alt="" />
        </div>
      </div>
      <div class="floor2">
        <div>
          <div>
            <h5>公司介绍</h5>
            <p>Brand</p>
            <p>
              佛山市南海区机械装备行业协会于2006年在南海区民政局的批准下成立，于2012年5月10日在南海区人民政府、南海区经济促进局及各镇街领导的指导下，通过换届的方式正式运作，目前共有会员企业近500家。
            </p>
            <p>
              本会的宗旨是：挖掘并有效运用创意和资源，协助本会企业提高机械产品和服务的附加值，以我们的诚信正直、融合贯通、灵活创新、公平公正、以人为本、全心全情的专业精神，与伙伴同行，致力协助本会机械装备企业达至卓越的生产力，提升竞争及持续发展能力，通过促进行业的精益生产而实现促进区工业经济的繁荣。
            </p>
          </div>
          <img src="../assets/image/8.jpg" alt="" />
        </div>
      </div>
      <div class="floor3">
        <div>
          <h6>
            2006
            <p>年</p>
          </h6>
          <p>协会正式运作于2006年</p>
        </div>
        <div>
          <h6>
            500
            <p>家</p>
          </h6>
          <p>近500家会员企业</p>
        </div>
        <div>
          <h6 style="border: none;">
           100+
            <p>场</p>
          </h6>
          <p>组织海外拓展活动超100场</p>
        </div>
      </div>
      <div class="floor4">
        <div class="title">
          <div class="le">
            <h3>新闻动态</h3>
            <p>News</p>
          </div>
        </div>
        <div class="box4">
          <div class="floor4-le">
            <a href="https://xhpfmapi.xinhuaxmt.com/vh512/share/11739301">
              <img src="../assets/image/15.jpg" alt="" />
              <div class="box_item" style="padding: 0px 10px">
                <h4>外贸指标展示积极信号 四季度向好势头有望继续巩固</h4>
                <img
                  src="../assets/image/右箭头.png"
                  class="arrowR"
                  alt=""
                  style="margin-top: 5px"
                />
              </div>
              <ul style="padding: 0px 10px">
                <li style="padding: 0">
                  <div
                    style="margin-top: -8px; border-right: 1.5px solid #e9e9e9"
                  >
                    <h4>2023</h4>
                    <p>10-26</p>
                  </div>
                  <div>
                    <p>上证报中国证券网讯近期外贸大省进出口指标积极向好</p>
                    <p>服贸会、广交会等大型展会已经相继举办</p>
                  </div>
                </li>
              </ul>
            </a>
          </div>
          <div class="floor4-ri">
            <ul>
              <a
                href="http://www.gd.gov.cn/gdywdt/ydylygd/content/post_4254727.html"
              >
                <li>
                  <div>
                    <h4>2023</h4>
                    <p>09-18</p>
                  </div>
                  <div>
                    <h5>前八月广东外贸进出口扭负转正</h5>
                    <p>八月份进出口实现两位数增长</p>
                  </div>
                </li>
              </a>
              <a
                href="https://www.chinanews.com.cn/cj/2023/10-20/10097615.shtml"
              >
                <li>
                  <div>
                    <h4>2023</h4>
                    <p>10-20</p>
                  </div>
                  <div>
                    <h5>从广交会看广东外贸三种“势”</h5>
                    <p>超十万名境外采购商线下参加广交会，下单意愿较强</p>
                  </div>
                </li>
              </a>
              <a href="https://www.workercn.cn/c/2023-10-26/8026396.shtml">
                <li style="border-bottom: 1.5px solid #e9e9e9">
                  <div>
                    <h4>2023</h4>
                    <p>10-26</p>
                  </div>
                  <div>
                    <h5>新能源产品大放异彩展中国外贸强韧性</h5>
                    <p>哇，你看，这是一辆新能源货车！”</p>
                  </div>
                </li>
              </a>
            </ul>
          </div>
        </div>
      </div>
      <div class="floor5">
        <div class="title">
          <div class="le">
            <h3>服务客户</h3>
            <p>Customer</p>
          </div>
        </div>
        <div class="box5">
          <div style="padding-left: 0">
            <img src="../assets/image/16.jpeg" alt="" />
          </div>
          <div>
            <img src="../assets/image/17.jpeg" alt="" />
          </div>
          <div>
            <img src="../assets/image/18.jpg" alt="" />
          </div>
          <div>
            <img src="../assets/image/19.jpeg" alt="" />
          </div>
          <div style="padding-right: 0">
            <img src="../assets/image/20.png" alt="" />
          </div>
        </div>
        <div class="box5">
          <div style="padding-left: 0">
            <img src="../assets/image/22.jpg" alt="" />
          </div>
          <div>
            <img src="../assets/image/23.jpeg" alt="" />
          </div>
          <div>
            <img src="../assets/image/24.png" alt="" />
          </div>
          <div>
            <img src="../assets/image/25.jpg" alt="" />
          </div>
          <div style="padding-right: 0">
            <img src="../assets/image/21.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      banner: [
        {
          id: 1,
          src: require("../assets/image/1.jpg"),
        },
        {
          id: 2,
          src: require("../assets/image/2.jpg"),
        },
        {
          id: 3,
          src: require("../assets/image/3.jpg"),
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
@import url(../assets/css/index.css);
@import url(../assets/css/common.css);
.el-carousel__item image {
  width: 100%;
  height: 850px;
  object-fit: cover;
  background-color: #741818;
}
.floor2 p {
  font-size: 18px;
}
.box_item {
  margin-bottom: 20px;
  display: flex;
}
.arrowR {
  width: 20px;
  height: 20px;
  margin-top: 10px;
  margin-left: auto;
}
.imgS {
  width: 100%;
  height: 210px;
  margin-bottom: 20px;
  object-fit: cover;
}
</style>
